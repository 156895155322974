@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&family=Khand:wght@300&family=Manrope:wght@200;300;500&family=Rubik+Bubbles&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: Khand
    }
}
